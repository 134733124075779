@import "variables.module"
@import "typography.module"
@import "reset.module"

hr
    border: 1px solid $Neutral06
    border-radius: 12px
    width: 100%

body
    background: $Neutral07
    margin: 0 !important
    padding: 0 !important

.page-loader
    +flex-column-center
    height: 100vh
    margin-right: auto
    margin-left: auto

/*
 * Uppy Dashboard Styles
 */
.uppy-Dashboard-inner,.uppy-Dashboard-innerWrap
    background: $Neutral06 !important
    border-radius: 8px !important

.uppy-Dashboard-AddFiles
    border-radius: 8px !important
    border: 2px dashed $Neutral04 !important

.uppy-Dashboard-AddFiles-title
    +sf-pro
    font-weight: 600 !important
    font-size: 20px !important
    line-height: 32px !important
    letter-spacing: -0.02em !important
    color: $Neutral03-5 !important
    button
        color: $Neutral00 !important
        &:hover
            border-bottom: 1px solid $Neutral00 !important

.uppy-StatusBar
    background: $Neutral06 !important

.uppy-StatusBar-status
    color: $Neutral00 !important

// Storybook
.storybookComponent
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    min-height: 90vh
