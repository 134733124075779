@import "mixins.module"

$PrimaryYellow: #FFB917
$PrimaryGreen: #27AA5C
$PrimaryRed: #FF5A5A
$PrimaryPurple: #EBD0FF
$PrimaryBrown: #FFFBEF
$PrimaryBlue: #22505B

$CompletedBlue: #56CCF2
$Orange: #F2994A
$SoftYellow: #ECF570

$SecondaryRed: #FCA896
$SecondaryPurple: #CABDFF
$SecondaryBlue: #EAF8FF
$SecondaryGreen: #B5E4CA
$SecondaryYellow: #FFD88D

$Neutral00: #FFFFFF
$Neutral01: #FCFCFC
$Neutral02: #F4F4F4
$Neutral03: #EFEFEF
$Neutral03-1: #C7CBD0
$Neutral03-5: #98A0A7
$Neutral04: #6F767E
$Neutral05: #33383F
$Neutral06: #272B30
$Neutral07: #1A1D1F
$Neutral08: #000000

$Shade04-75: #9A9FA5
$Shade04-50: #6F767E
$Shade04-40: rgba(111, 118, 126, 0.4)
$Shade08_Overlay: rgba(17, 19, 21, 0.5)

// Nav Constants
$HeaderHeight: 96px
$HeaderZIndex: 100
$CollapsedSideMenu: 96px
$DesktopSideMenu: 240px

$MobilePadding: 24px
$DesktopPadding: 16px

$ModalZIndex: 1000
$ContentMaxWidth: 1200px

// export variables for use in TS files
// https://nextjs.org/docs/basic-features/built-in-css-support#sass-variables
:export
    primaryYellow: $PrimaryYellow
    primaryGreen: $PrimaryGreen
    primaryRed: $PrimaryRed
    primaryPurple: $PrimaryPurple
    completedBlue: $CompletedBlue
    orange: $Orange
    secondaryRed: $SecondaryRed
    secondaryPurple: $SecondaryPurple
    secondaryBlue: $SecondaryBlue
    secondaryGreen: $SecondaryGreen
    secondaryYellow: $SecondaryYellow
    neutral00: $Neutral00
    neutral01: $Neutral01
    neutral02: $Neutral02
    neutral03: $Neutral03
    neutral04: $Neutral04
    neutral05: $Neutral05
    neutral06: $Neutral06
    neutral07: $Neutral07
    neutral08: $Neutral08
