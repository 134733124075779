@import "variables.module"

// Updating @font-face and font files: https://javascript.plainenglish.io/nextjs-with-self-hosted-fonts-for-all-browsers-673f497d11c4

// TODO Switch to Woff & Woff2 only. Add preload script tag -> https://kirazhang.com/posts/nextjs-custom-fonts
@font-face
	font-family: "SFProDisplay"
	src: url("/fonts/SFProDisplay-Medium.ttf") format("truetype")
	font-weight: normal
	font-style: normal
	font-display: swap

@font-face
	font-family: "SFProDisplay"
	src: url("/fonts/SFProDisplay-Semibold.ttf") format("truetype")
	font-weight: 600
	font-style: normal
	font-display: swap

@font-face
	font-family: "SFProDisplay"
	src: url("/fonts/SFProDisplay-Bold.ttf") format("truetype")
	font-weight: bold
	font-style: normal
	font-display: swap

@font-face
	font-family: "SFProDisplay"
	src: url("/fonts/SFProDisplay-Heavy.ttf") format("truetype")
	font-weight: 900
	font-style: normal
	font-display: swap

@font-face
	font-family: "VG5000"
	font-weight: normal
	font-style: normal
	src: url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/VG5000/VG5000-Regular_web.ttf') format('truetype')

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap')

// $Medium: normal
// $Semibold: 600
// $Bold: bold
// $Heavy: 900
// typography variables
=sf-pro
	font-family: "SFProDisplay", "Open Sans", sans-serif

=dm-sans
	font-family: 'DM Sans', sans-serif
	font-style: normal
	font-weight: 400

=vg5000
	font-family: 'VG5000'
	font-style: normal
	font-weight: 400

=h1
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 64px
	line-height: 64px
	letter-spacing: -0.03em

=h1-mobile-bold
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 26px
	line-height: 34px
	letter-spacing: -0.02em

=h2
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 48px
	line-height: 48px
	letter-spacing: -0.03em

=h3
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 40px
	line-height: 48px
	letter-spacing: -0.02em

=h4
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 28px
	line-height: 40px
	letter-spacing: -0.03em

=h5
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 18px
	line-height: 30px
	letter-spacing: -0.03em

=title1-medium
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 24px
	line-height: 32px
	letter-spacing: -0.02em

=title1-mobile
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 18px
	line-height: 32px
	letter-spacing: -0.02em

=title2-semibold
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 20px
	line-height: 32px
	letter-spacing: -0.02em

=title2-medium
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 20px
	line-height: 32px
	letter-spacing: -0.02em

=base1-semibold
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 15px
	line-height: 24px
	letter-spacing: -0.01em

=base1-bold
	+sf-pro
	font-style: normal
	font-weight: bold
	font-size: 15px
	line-height: 24px
	letter-spacing: -0.01em

=body1-medium
	+sf-pro
	font-weight: normal
	font-size: 15px
	line-height: 24px
	letter-spacing: -0.015em

=body1-semibold
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 15px
	line-height: 24px
	letter-spacing: -0.01em

=base2
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 14px
	line-height: 24px
	letter-spacing: -0.01em

=body2-semibold
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 14px
	line-height: 24px
	letter-spacing: -0.01em

=body2-medium
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 14px
	line-height: 24px
	letter-spacing: -0.01em

=caption1
	+sf-pro
	font-style: normal
	font-weight: 600
	font-size: 13px
	line-height: 16px
	letter-spacing: -0.01em

=caption1-medium
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 13px
	line-height: 16px
	letter-spacing: -0.01em

=caption2
	+sf-pro
	font-style: normal
	font-weight: bold
	font-size: 12px
	line-height: 16px
	letter-spacing: -0.01em

=caption2-medium
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 12px
	line-height: 16px
	letter-spacing: -0.01em

=caption3
	+sf-pro
	font-style: normal
	font-weight: normal
	font-size: 10px
	line-height: 14px

=button1
	+sf-pro
	font-style: normal
	font-weight: bold
	font-size: 15px
	line-height: 24px
	letter-spacing: -0.01em

=button2
	+sf-pro
	font-style: normal
	font-weight: bold
	font-size: 13px
	line-height: 24px
	letter-spacing: -0.01em

=button3
	+vg5000
	font-size: 16px
	line-height: 19px
	text-align: center
	letter-spacing: 0.05em
	text-transform: uppercase
