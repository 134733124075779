=flex-column
    display: flex
    flex-direction: column

=flex-column-center
    +flex-column()
    align-items: center
    justify-content: center

=flex-row
    display: flex
    flex-direction: row

=flex-row-center
    +flex-row()
    align-items: center
    justify-content: center

=flex-row-between
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

=center-element
    margin-left: auto
    margin-right: auto

=max-width-centered
    max-width: $ContentMaxWidth
    margin-left: auto
    margin-right: auto

// media queries - Based of Material UI
$XL: 1536
=xl
    @media (min-width: $XL + "px")
        @content
$LG: 1200
=lg
    @media (min-width: $LG + "px")
        @content
$MD: 900
=md
    @media (min-width: $MD + "px")
        @content
$SM: 600
=sm
    @media (min-width: $SM + "px")
        @content
$XS: 474
=xs
    @media (max-width: $XS + "px")
        @content

// From helpers from other theme
// placeholder
=placeholder
    &::placeholder
        @content

// media query width
=r($width)
    @media (max-width: $width+ "px")
        @content

=rmin($width)
    @media (min-width: $width+ "px")
        @content

=text-overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

// Common style patterns
=card
    +flex-column-center
    background: $Neutral06
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5)
    border-radius: 12px
    padding: 16px

=vertical-divider
    +flex-column-center
    border: 1px solid $Neutral06
    width: 0
    height: 100%

=hover-pointer
    &:hover
        cursor: pointer

=max-width-center
    max-width: $ContentMaxWidth
    width: 100%
    margin: 0 auto

/*
 * Custom Scrollbar
 * https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/
 */
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%))
    overflow-y: auto
    // For Google Chrome
    &::-webkit-scrollbar
        width: $size

    &::-webkit-scrollbar-thumb
        border-radius: 8px
        background: $foreground-color

    &::-webkit-scrollbar-track
        border-radius: 8px
        background: $background-color
        height: 100%

    // For Internet Explorer
    &
        scrollbar-face-color: $foreground-color
        scrollbar-track-color: $background-color

@mixin notification($size: 14px, $top: auto, $right: auto, $bottom: auto, $left: auto)
    &::before
        content: ""
        position: absolute
        top: $top
        right: $right
        bottom: $bottom
        left: $left
        width: $size
        height: $size
        border-radius: 50%
        border: 2px solid $Neutral01
        background: $PrimaryRed

/** 
 * Functions
 */
@function spacing($units)
    @return (8 * $units) + px
